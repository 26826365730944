/*
 * Main Stylesheet for Debt Free Living
 *
 * Contents
 *   1. Global
 *   2. Navigation
 *   3. Home Page
 *   4. Blog
 *   5. About
 *   6. Contact
 *   7. Calculator
 *   8. Login/Forgot Password
 *   9. Admin
 *   10. Dashboard
 *  11. Account
 *  12. Footer
 *  13. Media Queries
 */

/***********************************/
/************* GLOBAL ************/
/*******************************/
body {
  padding-top:75px;
}

section:not(#hero) {
  margin-top: 30px;
}

.img-responsive {
  margin: 0 auto;
}

img.rounded {
  border-radius: 6px;
}


/***********************************/
/*********** NAVIGATION **********/
/*******************************/
.navbar-brand {
  padding: 0;
  width: 250px;
  height:74px;
  margin-right:15px;
}

.navbar-brand img {
  width: 100%;
  margin-top: 5px;
}

.nav.navbar-nav {
  margin-top: 13px;
  font-size: 18px;
}

.nav .border-right {
    border-right: 1px solid #777;
}

.navbar-toggle {
    margin-top: 22px;
}

.nav.navbar-right {
  font-size:14px;
}

.nav.navbar-nav.navbar-right .logout a {
  color: #a94442;
}

/*****************************/
/*********** HOME **********/
/*************************/

/* Carousel */
#hero .carousel-inner {
  margin-bottom: 30px;
}

#hero .carousel-control {
  display:none;
}

.carousel-caption {
  text-shadow:none;
}

.carousel-caption .faded {
  background: rgba(0,0,0,.5);
  padding: 15px;
  border-radius: 8px;
  display: inline-block;
  min-width:420px;
  margin-bottom: 50px;
}

.carousel.fade {
  opacity: 1;
}

.carousel.fade .item {
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  left: 0 !important;
  opacity: 0;
  top:0;
  position:absolute;
  width: 100%;
  display:block !important;
  z-index:1;
}

.carousel.fade .item:first-child {
  top:auto;
  position:relative;
}

.carousel.fade .item.active {
  opacity: 1;
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  z-index:2;
}

.slider-images {
  margin:auto;
  min-width:960px;
  min-height:300px;
}

.slide-title {
  font-size:40px;
}

.slide-caption {
  font-size:26px;
}

.slide-button {
  font-size:18px;
  padding: 10px;
}

/* Features */
#featurette {
  padding-left: 15px;
  padding-right: 15px;
}

#featurette p {
  font-size:16px;
}

#featurette .col-sm-4 {
  text-align: center;
  margin-bottom: 20px;
}

#featurette h3 {
  font-weight: normal;
  margin-top:10px;
}

#featurette .col-sm-4 p {
  margin-left: 10px;
  margin-right: 10px;
}

.feature-icons {
  color: #5cb85c;
}

.feature-blurb {
  min-height:68px;
}

.feature-btn {
  margin-top:10px;
  margin-bottom:20px;
}

/* Testimonials */
#testimonials {
  background: #efefef;
  padding: 40px;
}

.testimonials {
  padding:20px;
  min-height:250px;
}
.testimonial-image {
  width:180px;
  height:180px;
}

.testimonials .text-wrapper {
  text-align:left;
  padding:15px 30px 0 0;
}

.testimonial-text {
  font-size:16px;
  font-style:italic;
}

.testimonial-name {
  font-size: 16px;
}

#testimonials .carousel-control.left, #testimonials .carousel-control.right {
  background-image: none;
}

#testimonials .carousel-control {
  text-shadow: none;
  width:5%;
  color: #000;
}

#testimonials .carousel-indicators {
  display:none;
}

#bio p {
  font-size:16px;
}

#bio a.btn {
  margin:20px 0;
}

/***********************************/
/************** BLOG *************/
/*******************************/
.read-more {
  margin-top:20px;
  margin-bottom:30px;
}

.title {
  color: #337ab7;
}

.author {
  color: #337ab7;
}

.no-border {
  border:none;
}

.space-left {
  margin-left:5px;
}

.side-widgets {
  margin-top: 80px;
}

.clear-search {
  font-size:75%;
}

.comment-img {
  width:40px;
  height:40px;
}

.default {
  border:1px solid lightgrey;
  background:#ccc;
}

#comment-form .form-group {
  position:relative;
}

.back-blog a {
  margin: 30px 0;
}

/***********************************/
/************* ABOUT *************/
/*******************************/
#about a.btn {
  margin:20px 0;
}

#about .founder-photo img {
  margin-top:10px;
  margin-bottom:20px;
}

#dflTeam img {
  margin: 20px auto;
}

/********************************/
/*********** CONTACT **********/
/****************************/
.contact-icons {
  margin: 30px 0;
}

#contact a.facebook:hover, #contact a.facebook:focus {
  color: #3b5998;
}

#contact a.twitter:hover, #contact a.twitter:focus {
  color: #55acee;
}

#contact a.pinterest:hover, #contact a.pinterest:focus {
  color: #bd081c;
}

#contact a.envelope:hover, #contact a.envelope:focus {
  color: #C5A528;
}

#contact .captcha,
#login .captcha {
  display:inline-block;
}


/**********************************/
/*********** CALCULATOR **********/
/********************************/

 .center {
   margin-left: auto;
   margin-right: auto;
 }

 .debtLabel {
   margin-left: 15px;
 }

 #outputTable {
   width: 100%;
   margin-left: 0px;
   margin-right: 0px;
 }

 .strip1{
   background-color: #F8F8F8;
 }

 .strip2{
 	background-color: #E8E8E8
 }

/************************************/
/***** LOGIN/FORGOT PASSWORD ******/
/********************************/
.panel {
  background: #efefef;
  background: -moz-linear-gradient(top, #FFFFFF 0%, #F4F4F4 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#FFFFFF), color-stop(100%,#F4F4F4));
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#F4F4F4',GradientType=0 );
}

.panel-body {
  border: none;
  box-shadow: none;
}

.login-side {
  border-right:none;
  position: relative;
}

.login-side .border-right {
  border-right: 1px solid #ccc;
  position: absolute;
  top: 30px;
  bottom: 0px;
  right: 0;
}

.register-side {
  border-left:none;
}

#login-form input,
#register-form input,
#forgot-form input,
#contact-form input,
#contact-form textarea,
#comment-form textarea,
#loan-form input {
  padding-left:30px;
}

#login-form .icons,
#register-form .icons,
#forgot-form .icons,
#contact-form .icons,
#comment-form .icons,
#loan-form .icons {
  left:0;
}

.or {
  text-align:center;
  position:relative;
  z-index:2;
  margin: 30px 0;
}

.or:after {
  content:"";
  position:absolute;
  top:50%;
  left:0;
  right:0;
  border-top:1px solid lightgrey;
  z-index:-1;
}

.hr-ed {
  margin:0;padding:0 10px;
  background: #F7F7F7;
  display:inline-block;
}

.oauth a {
  margin-bottom:10px;
  font-weight:bold;
  color: #fff;
  border:none;
}

.oauth a i {
  float:left;
  font-size:1.5em;
}

.oauth .oauth-facebook, .oauth .oauth-facebook:hover, .oauth .oauth-facebook:active, .oauth .oauth-facebook:focus {
  background: #3b5998;
  color:#fff;
}

.oauth .oauth-google, .oauth .oauth-google:hover, .oauth .oauth-google:active, .oauth .oauth-google:focus {
  background:#DC4E41;
  color:#fff;
}

#register-form .has-error .checkbox-inline {
    color: #333;
}

/*****************************/
/*********** ADMIN *********/
/*************************/
.admin-buttons {
  margin-bottom:20px;
}


/************************************/
/*********** DASHBOARD ************/
/********************************/
#dashboard .panel {
  margin-bottom: 20px;
  border-color: #ddd;
  color: #333;
  background:none;
}

#dashboard .create-plan {
  margin: 96px 0;
}

#dashboard .create-plan p {
  font-size: 18px;
  margin: 40px 0;
}

#dashboard .create-plan a {
  margin: 20px 0;
}

#dashboard .blog-spinner {
  margin: 180px 0;
}

.panel-flat>.panel-heading {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
}

#dashboard .panel-body,
#dashboard .panel-footer,
#dashboard .panel-heading,
#dashboard .panel-title {
    position: relative;
}
.h5.panel-title, h5.panel-title {
    font-size: 17px;
}

#dashboard .panel-flat>.panel-heading+.panel-body {
    padding-top: 0;
}

.form-control-feedback.icons.margin-left {
  margin-left:14px;
}

/************************************/
/************* ACCOUNT ************/
/********************************/
#account form input {
  padding-left:30px;
}

#account form .icons {
  left:0;
}


/***********************************/
/************* FOOTER ************/
/*******************************/
footer {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
  padding:10px 0 0 0;
  margin-top: 30px;
}

footer .social-section {
  padding: 10px 0;
}

.social-icons {
  color: #444;
}

footer a.facebook:hover, footer a.facebook:focus {
  color: #3b5998;
}

footer a.twitter:hover, footer a.twitter:focus {
  color: #55acee;
}

footer a.pinterest:hover, footer a.pinterest:focus {
  color: #bd081c;
}

.footer-nav {
  float:none;
}

.footer-nav > li {
  display:inline-block;
}

.copyright {
    margin-top:10px;
}

.copyright, .copyright a {
  color: #9d9d9d;
  font-size:12px;
}



/***********************************/
/********* MEDIA QUERIES *********/
/*******************************/

@media (max-width: 1200px) {
  .slide-title {
    font-size:36px;
  }

  .slide-caption {
    font-size:22px;
  }

  .slide-button {
    font-size:14px;
    padding: 10px;
  }

  .carousel-caption .faded {
    margin-bottom: 20px;
  }
}

@media (min-width: 1008px) {
  .blog-spinner {
    margin-top:150px;
  }
}

@media (min-width: 992px) {
  #about .founder-photo {
    float:right;
  }

  #dashboard .payment-schedule {
    margin-bottom: -10px;
  }

  .navbar-brand {
    margin-right: 50px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  #login .captcha {
    transform:scale(0.8);
    transform-origin:0 0;
  }
}

@media (max-width: 991px) {

  #testimonials {
    padding: 10px;
  }

  .testimonials {
    min-height: 450px;
  }

  .testimonials .text-wrapper {
    padding: 15px 30px 0 30px;
  }

  .testimonial-text {
    font-size: 16px;
  }

  .testimonial-name {
    font-size: 14px;
  }

  .carousel-caption .faded {
    min-width:420px;
  }
}


@media (max-width: 767px) {

  .navbar-brand img {
    margin-left:10px;
  }

  .login-side .border-right {
    display:none;
  }

  .heading-elements .icons-list {
      margin-top: 9px;
  }

  .heading-elements .breadcrumb, .heading-elements .daterange-custom,
  .heading-elements .heading-btn, .heading-elements .heading-form,
  .heading-elements .heading-text, .heading-elements .icons-list,
  .heading-elements .nav-pills, .heading-elements .nav-tabs,
  .heading-elements .noui-slider, .heading-elements .pager,
  .heading-elements .pagination, .heading-elements .progress,
  .heading-elements .ui-slider, .heading-elements>.btn-group {
      float: left;
      margin-left: 20px;
  }

  .heading-elements-toggle {
    display: none;
  }

  .carousel-caption .faded {
    min-width:0;
  }

  #hero .off-center {
    width:100%;
    margin-left:-220px;
  }

  .slide-title {
    font-size:30px;
  }

  .slide-caption {
    font-size:16px;
  }

  .slide-button {
    font-size:12px;
    padding: 5px;
  }

  .hr-ed {
    background: #FBFBFB;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 380px) {
  .captcha {
    transform:scale(.8);
    transform-origin:0 0;
  }
  .navbar-brand {
     width: 220px;
   }
   .navbar-brand img {
     margin-top: 9px;
   }
}
